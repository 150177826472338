.foot-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #555555;
    opacity: 0.5;
    height: 35px;
    font-size: 16px;
    font-family: Forte, sans-serif;
    transition: background-color .4s;
}

.foot-box.mouse-on {
    background-color: #222222;
}

.foot-box.wechat-box {
    transition: height .4s;
    justify-content: start;
}

.foot-box.wechat-box.is-wechat-open {
    height: 200px;
}

.wechat-img {
    height: 0px;
    width: 150px;
    opacity: 0;
    transition: opacity .3s;
}

.wechat-img.wechat-img-show{
    opacity: 100;
}